import React from 'react';
import { Flex, Stack, Text, Accordion, Box } from '@chakra-ui/core';
import { CustomAccordionItem } from 'components/shared';

export default function Faq() {
  return (
    <Flex
      width="100%"
      height="100%"
      fontSize="sm"
      backgroundColor="#AFE5F8"
      justifyContent="center"
    >
      <Box
        px={3}
        py={4}
        width="5xl"
        fontSize="1rem"
        lineHeight="1.3125rem"
        letterSpacing="0.01em"
        maxWidth="960px"
      >
        <Text
          textAlign="center"
          pt="2rem"
          fontSize="30px"
          color="#083261"
          fontWeight="600"
        >
          Frequently asked questions
        </Text>

        <Accordion allowMultiple py={6}>
          <Stack
            justify="center"
            alignItems="center"
            mb="1.88rem"
            w="100%"
            fontSize="1rem"
          >
            <CustomAccordionItem
              spacing={1}
              title="What are the benefits of upgrading to a Gold Membership?"
            >
              Gold Members have full access to our entire library of more than
              1,000 books and counting, as well as our main features like
              bookmarks, custom bookshelves, and more!
            </CustomAccordionItem>
            <CustomAccordionItem
              spacing={1}
              title="What is the difference between a Gold Plan and a Gold Family Plan?"
            >
              With a Gold Family Plan, the paying subscriber can gift their
              Readeo Gold benefits to 4 other people for free. This allows other
              households to have all the same benefits of the paying member,
              such as full library access and the ability to create bookshelves
              and bookmarks.
            </CustomAccordionItem>
            <CustomAccordionItem
              spacing={1}
              title="How do I add/remove members to my Gold Family Plan?"
            >
              The first 4 buddies you invite to join your family plan will
              automatically receive all the same Gold Member benefits you have
              at no cost to them. If you add more than 4 buddies to your
              account, you can manage who’ll receive the Gold Member benefits in
              your “My Account” tab.
            </CustomAccordionItem>
            <CustomAccordionItem
              spacing={1}
              title="Can a Free Member BookChat with a Gold Member?"
            >
              Yes! Anytime a Gold Member is BookChatting with a Free Member,
              both users will have full access to the library. This means that
              they will not be limited to the Free Member book selection, but
              will have access to our entire library of more than 1,000 books!
            </CustomAccordionItem>
            <CustomAccordionItem
              spacing={1}
              title="Is there any sort of time limit or book restraint for reading sessions?"
            >
              There are no time limits for any of our users, no matter their
              membership level. However, Gold Members have full access to our
              library while Free Members are limited to reading one of our five
              free books (swapped out monthly).
            </CustomAccordionItem>
            <CustomAccordionItem
              spacing={1}
              title="Is a credit card required at signup if I’m signing up for a Free Membership? "
            >
              No! Signing up for a Free Membership does not require a credit
              card.
            </CustomAccordionItem>
          </Stack>
        </Accordion>
      </Box>
    </Flex>
  );
}
