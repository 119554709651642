import React, { useState } from 'react';
import { Box, Flex, Button, Text } from '@chakra-ui/core';

import { StyledButton } from './StyledButton';
import { Plans } from './types';
import config from 'config';

export default function PricingPlansMobile() {
  const [activeBtn, setActive] = useState<'yearly' | 'monthly'>('yearly');
  const [plan, setPlan] = useState<Plans>('family-gold');
  const planPrice = {
    yearly: { priceFamily: '$99.99' },
    monthly: { priceFamily: '$14.99' },
  };

  const handleDuration = (duration: 'yearly' | 'monthly') =>
    setActive(duration);

  const handlePlan = (plan: Plans) => setPlan(plan);

  return (
    <Flex
      padding="0 1rem"
      flexDirection="column"
      justifyContent="space-between"
      height="100vh"
      backgroundColor="rgba(175, 220, 255, 0.3)"
      marginTop="0rem"
    >
      <Text fontSize="25px" textAlign="center" marginBottom="0.3rem">
        Choose Mobile Plan
      </Text>
      <Flex
        backgroundColor="#083261"
        padding="1rem"
        borderRadius="100px"
        height="50px"
        color="white"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Button
          borderRadius="45px"
          width="45%"
          backgroundColor={activeBtn !== 'yearly' ? '#019CD5' : 'transparent'}
          onClick={() => handleDuration('monthly')}
          _focus={{
            backgroundColor: activeBtn !== 'yearly' ? '#019CD5' : 'transparent',
          }}
        >
          Monthly
        </Button>
        <Button
          borderRadius="45px"
          width="45%"
          backgroundColor={activeBtn === 'yearly' ? '#019CD5' : 'transparent'}
          onClick={() => handleDuration('yearly')}
          _focus={{
            backgroundColor: activeBtn === 'yearly' ? '#019CD5' : 'transparent',
          }}
        >
          Yearly
        </Button>
      </Flex>
      <div>
        <Flex
          backgroundColor={'white'}
          padding=".5rem .5rem"
          margin=".5rem 0"
          width="100%"
          cursor="pointer"
          justifyContent="space-between"
          alignItems="center"
          border={plan === 'family-gold' ? '2px solid #F38530' : 'none'}
          style={{
            background:
              'linear-gradient(105.74deg, rgba(255, 255, 255, 0.8) 2.91%, rgba(255, 255, 255, 0.3) 90.22%)',
            borderRadius: '14px',
          }}
          onClick={() => handlePlan('family-gold')}
        >
          <Flex flexDirection="column" width="225px" justifyContent="center">
            <Text
              fontSize="21px"
              lineHeight="18px"
              fontWeight="600"
              marginBottom="0.5rem"
            >
              {' '}
              Gold Family Plan
            </Text>
            <Text fontSize="14px" fontWeight="500" lineHeight="17px">
              Share this plan with<br></br>4 others for them to enjoy the same
              Readeo Gold<br></br>
              benefits
            </Text>
            {activeBtn === 'yearly' && (
              <Button
                borderRadius="14px"
                height="28px"
                border="1px solid #083261"
                backgroundColor="transparent"
                width="70%"
                marginTop="1rem"
              >
                Save 33%
              </Button>
            )}
          </Flex>
          <Flex flexDirection="column" padding="0.5rem 0" maxW="127px">
            <Text
              fontSize="36px"
              lineHeight="16px"
              marginBottom="0.5rem"
              textTransform="uppercase"
              fontWeight="600"
            >
              {planPrice[activeBtn].priceFamily}
            </Text>
            <Text
              fontWeight="bold"
              fontSize="18px"
              lineHeight="16px"
              marginBottom="1rem"
              letterSpacing="-0.01em"
              textAlign="center"
            >
              {activeBtn === 'yearly' ? 'year' : 'Month'}
            </Text>
            {activeBtn === 'yearly' && (
              <Text
                fontSize="13px"
                lineHeight="17px"
                marginTop="0.5rem"
                textAlign="left"
              >
                $9.99/mo when billed annually
              </Text>
            )}
          </Flex>
        </Flex>
      </div>
      <Box width="100%">
        <StyledButton
          textAlign="center"
          width="98%"
          height="50px"
          alignSelf="left"
          textTransform="uppercase"
          fontWeight="bold"
          fontSize="13pt"
          _hover={{
            background:
              'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #f38530',
          }}
          onClick={() => (window.location.href = `${config.appUrl}/signup`)}
        >
          START READING
        </StyledButton>
      </Box>
    </Flex>
  );
}
