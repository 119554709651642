import React from 'react';
import {
  Box,
  Flex,
  Grid,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
} from '@chakra-ui/core';

// assets
import { ReactComponent as RedCancelMark } from 'assets/svg/red-cancel-icon.svg';
import { ReactComponent as GreenCheckMark } from 'assets/svg/green-check-icon.svg';
import { ReactComponent as Exclamation } from 'assets/svg/exclamation-mark.svg';
import { ReactComponent as QuestionMark } from 'assets/svg/question-mark.svg';

const RenderItem = ({
  children,
  withPopover,
  background,
  type,
  align = 'inherit',
}: {
  children: React.ReactNode;
  withPopover?: boolean;
  background?: string;
  type?: any;
  align?: 'center' | 'inherit';
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      h="60px"
      background={background}
      width="100%"
      justifyContent={align}
      px=".5em"
    >
      {withPopover ? (
        <>
          {children}
          <Popover trigger="hover" placement="top">
            <PopoverTrigger>
              <Exclamation
                style={{ display: 'inline', marginLeft: '.25rem' }}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverBody textAlign="center">
                {type === 1 && (
                  <>
                    Create as many bookshelves as you want with as many books as
                    you like. Plus, you can name them anything you wish – like
                    'Ryan's Bedtime Stories'.
                  </>
                )}
                {type === 2 && (
                  <>
                    If you don't love Readeo, you can cancel anytime during the
                    two-week period without being charged.
                  </>
                )}
                {type === 3 && (
                  <>
                    Bedtime always comes too soon — mark your place and pick up
                    right where you left off, whenever!
                  </>
                )}
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </>
      ) : (
        children
      )}
    </Box>
  );
};

export default function PlanDescriptionMobile() {
  return (
    <Box pb="48px">
      <Grid
        gridTemplateColumns="60% auto auto"
        fontSize="14px"
        fontWeight="600"
        lineHeight="17px"
        letterSpacing="0.01em"
      >
        <Box>
          <Box width="100%" height="40px" />
          <RenderItem>
            <Text>Bookchat with the ones you love</Text>
          </RenderItem>
          <RenderItem background="#fff">
            <Text>Full Library Access</Text>
          </RenderItem>
          <RenderItem withPopover type={1}>
            <Text> Customized bookshelves</Text>
          </RenderItem>
          <RenderItem withPopover type={3} background="#fff">
            <Text> Create Bookmarks</Text>
          </RenderItem>
        </Box>
        <Flex
          borderLeft="0.4px solid #083261"
          flexDirection="column"
          alignItems="center"
        >
          <Text h="40px" paddingTop="12px">
            Free
          </Text>
          <RenderItem align="center">
            <GreenCheckMark />
          </RenderItem>
          <RenderItem align="center" background="#fff">
            <Box
              textAlign="center"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Text mb=".5rem">Limited</Text>
              <Box
                display="flex"
                alignItems="center"
                //h="60px"
                // background={background}
                //  width="100%"
                // justifyContent={align}
              >
                <Popover trigger="hover" placement="top-end">
                  <PopoverTrigger>
                    <QuestionMark
                      style={{ display: 'inline', marginLeft: '.25rem' }}
                    />
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverBody textAlign="center">
                      Free members can only read our 5 free books (which we swap
                      out monthly), or preview the first few pages of any of our
                      other books
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Box>
            </Box>
          </RenderItem>
          <RenderItem align="center">
            <RedCancelMark />
          </RenderItem>
          <RenderItem align="center" background="#fff">
            <RedCancelMark />
          </RenderItem>
        </Flex>
        <Flex
          borderLeft="0.4px solid #083261"
          flexDirection="column"
          alignItems="center"
        >
          <Flex
            background="linear-gradient(108.67deg, rgba(255, 255, 255, 0) 21.39%, rgba(255, 255, 255, 0.4) 42.04%, rgba(255, 255, 255, 0) 58.56%, rgba(255, 255, 255, 0.4) 76.73%, rgba(255, 255, 255, 0.211386) 81.96%), linear-gradient(0deg, #EABF6B, #EABF6B), #083261;"
            width="100%"
            h="40px"
            justifyContent="center"
            alignItems="center"
            px="1.5em"
          >
            <Text>Gold</Text>
          </Flex>
          <RenderItem align="center">
            <GreenCheckMark />
          </RenderItem>
          <RenderItem align="center" background="#fff">
            <GreenCheckMark />
          </RenderItem>
          <RenderItem align="center">
            <GreenCheckMark />
          </RenderItem>
          <RenderItem align="center" background="#fff">
            <GreenCheckMark />
          </RenderItem>
        </Flex>
      </Grid>
    </Box>
  );
}
